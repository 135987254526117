<template>
  <li
    :key="notification.id"
    class="notification-list__item"
  >
    <div class="item__left">
      <AppCombobox
        v-model="notification.is_active"
        position-input="end"
      />
    </div>
    <div class="item__right">
      {{ notification.type.title }}
    </div>
  </li>
</template>

<script>
import AppCombobox from '@/components/common/simple/AppCombobox'
export default {
  name: 'UserNotificationItem',
  components: { AppCombobox },
  props: {
    notification: {
      type: Object,
      required: true
    },
    notificationList: {
      type: Array,
      required: true
    },
  },
  watch: {
    'notification.is_active' (val) {
      if (val !== this.notification.isActive) {
        this.updateNotificationListInServer()
      }
    }
  },
  methods: {
    updateNotificationListInServer () {
      try {
        this.$store.dispatch(
          'users/fetchUpdateUserData',
          { userData: { notificationList: this.notificationList } }
        ).then(() => {
          this.$store.dispatch('organization/fetchOrganizationUser')
        })
      } catch (e) {
        this.$store.dispatch('users/fetchUserData')
        console.log(e)
      }
    }
  }
}
</script>


<style lang="sass" scoped>
  .notification-list
    &__item
      display: flex
      width: 100%
      margin-top: 25px
      &:first-of-type
        margin-top: 0

    .item__left
      width: 30%
      align-self: end

    .item__right
      @extend %value-text
      text-overflow: ellipsis
      width: 70%
      text-align: left
      margin-left: 40px
</style>
