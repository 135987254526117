<template>
  <ul
    v-if="notificationList"
    class="notification-list"
  >
    <UserNotificationItem
      v-for="notification in getNotificationList"
      :key="notification.id"
      :notificationList="notificationList"
      :notification="notification"
    />
  </ul>
</template>

<script>
import UserNotificationItem from '@/components/users/UserNotificationItem'

export default {
  name: 'UserNotificationList',
  components: { UserNotificationItem },
  props: {
    notificationList: {
      type: Array,
      required: true
    }
  },
  computed: {
    getNotificationList: function () {
      const vm = this
      return vm.notificationList.sort(function (a, b) {
        if (a.id > b.id) {
          return 1
        }
        if (a.id < b.id) {
          return -1
        }
        // a должно быть равным b
        return 0
      })
    }
  },
}
</script>

<style lang="sass" scoped>
  .notification-list
    display: flex
    flex-direction: column
    width: 100%
    margin: 10px 0
</style>
