<template>
  <AppMainContainer
    center-class="user-card"
    :show-modal="showPasswordUpdate"
    @closeModal="showPasswordUpdate = false"
  >
    <template #subheader>
      <div
        style="
      display: flex;
      flex: 1; flex-direction: row; justify-content: flex-start; align-items: center;"
      >
        <AppButton
          style="align-self: flex-end; color: #0c0c0c"
          class="logout-button"
          :class="{'inactive-tab': currentView !== viewList.PROFILE}"
          @click.native.prevent="currentView = viewList.PROFILE"
        >
          Персональные данные
        </AppButton>

        <AppButton
          style="align-self: flex-end; color: #0c0c0c"
          class="logout-button"
          :class="{'inactive-tab': currentView !== viewList.SETTING}"
          @click.native.prevent="currentView = viewList.SETTING"
        >
          Настройки
        </AppButton>
      </div>
    </template>
    <template #center>
      <user-info-list
        v-if="currentView === viewList.PROFILE && userInfo && userInfo.id"
        :user-info="userInfo"
        class="user-card__user-info"
        is-edit-current-user
        @onPageRequesting="onPageRequesting"
      />
      <template v-if="currentView === viewList.SETTING && !pageRequesting">
        <div class="user-card__user-notification">
          <AppSectionHeader>
            <template #headerText>
              {{ $t('user_card.notification.header.text') }}
            </template>
          </AppSectionHeader>

          <UserNotificationList
            v-if="userInfo"
            :notification-list="userInfo.notificationList"
          />
        </div>

        <div class="password-group">
          <AppSectionHeader>
            <template #headerText>
              {{ $t('user_card.password.header.text') }}
            </template>
          </AppSectionHeader>

          <UserPasswordGroupInput
            :password.sync="formPassword.password"
            :repeat-password.sync="formPassword.repeatPassword"
            @changeValidPassword="checkPasswordValid"
          />

          <AppButton
            class="password-group__button"
            :disabled="!passwordValid"
            @click.native.prevent="updatePassword"
          >
            {{ $t('user_card.password.button.save') }}
          </AppButton>
        </div>
      </template>
      <template v-if="!pageRequesting">
        <div
          style="
      display: flex;
      flex: 1; flex-direction: row; justify-content: flex-end; align-items: flex-end;"
        >
          <AppButton
            style="align-self: flex-end"
            class="logout-button"
            @click.native.prevent="logout"
          >
            {{ $t('user_card.button.logout') }}
          </AppButton>
        </div>
      </template>
    </template>
    <template #modal>
      <AppModalSmall
        v-show="showPasswordUpdate"
        style="padding: 70px 106px 35px 106px"
      >
        <h1 class="modal__header">
          Пароль успешно обновлен!
        </h1>

        <div class="modal__button">
          <AppButton
            @click.native="showPasswordUpdate = false"
          >
            Закрыть
          </AppButton>
        </div>
      </AppModalSmall>
    </template>
  </AppMainContainer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import AppSectionHeader from '@/components/common/AppSectionHeader'
import UserPasswordGroupInput from '@/components/users/UserPasswordGroupInput'
import UserNotificationList from '@/components/users/UserNotificationList'
import UserInfoList from '@/components/users/UserInfoList'
import AppButton from '@/components/common/simple/AppButton'
import AppModalSmall from '@/components/common/modal/AppModalSmall'

export default {
  name: 'PageUserCard',
  components: {
    AppModalSmall,
    AppButton,
    UserInfoList,
    UserNotificationList,
    UserPasswordGroupInput, AppSectionHeader, AppMainContainer
  },
  data () {
    return {
      formPassword: {
        password: '',
        repeatPassword: ''
      },
      viewList: {
        'PROFILE': 'PROFILE',
        'SETTING': 'SETTING',
      },
      currentView: null,
      passwordValid: false,
      pageRequesting: false,
      showPasswordUpdate: false
    }
  },
  computed: {
    ...mapState('users', {
      userInfo: 'info'
    }),
  },
  created () {
    this.$store.commit('users/setEditedUser', null)
    this.currentView = this.viewList.PROFILE
    this.$store.dispatch('users/fetchUserData')
  },

  methods: {
    ...mapActions('users', [
      'fetchUpdatePassword',
      'logout'
    ]),
    checkPasswordValid (isValid) {
      this.passwordValid = isValid
    },
    onPageRequesting (value) {
      this.pageRequesting = value
    },
    updatePassword () {
      this.fetchUpdatePassword(this.formPassword)
        .then(() => {
          this.showPasswordUpdate = true
          this.formPassword.password = ''
          this.formPassword.repeatPassword = ''
        })
    }
  },

}
</script>

<style lang="sass" scoped>

  .inactive-tab
    opacity: 0.3
  .password-group
    display: flex
    flex-direction: column
    &__button
      margin: 20px auto 0 auto
    &__user-notification
      margin-top: 60px
    &__notification-list
      display: flex
      flex-direction: column
      width: 100%
      margin: 10px 0
      &__item
        display: flex
        width: 100%
        margin-top: 25px
        &:first-of-type
          margin-top: 0
</style>
